
.add-subject-option {
    height: 100%;
    padding-top: 10px;
    .subject-ruleForm {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        ::v-deep .el-form-item__label {
            text-align: center;
        }
        .subject-content {
            flex: 1;
            height: calc(100% - 63px);
            padding-right: 15px;
            .subject-quill {
                height: 200px;
                margin-bottom: 42px;
                ::v-deep .el-form-item__content {
                    height: 200px;
                    .quill-editor {
                        height: 200px;
                        .ql-container {
                            height: 100px;
                        }
                    }
                }
            }
            .subject-fill {
                height: 200px;
                margin-bottom: 42px;
                ::v-deep .el-form-item__content {
                    height: 200px;
                    .quill-editor {
                        height: 200px;
                        .ql-container {
                            height: 100px;
                        }
                    }
                }
            }
            .subject-short-answer {
                display: none;
            }
        }
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
 }
